
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IConfiguracao, IConfiguracaoSmtp } from '@/models/Entidades/IConfiguracao';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import ComboTipoClientes from '@/components/ComboTipoClientes.vue';
import ComboFormaAtivacaoClientes from '@/components/ComboFormaAtivacaoClientes.vue';
import ComboFormaApresentacaoPrecos
  from '@/components/ComboFormaApresentacaoPrecos.vue';
import ComboConceitoPrecificacao
  from '@/components/ComboConceitoPrecificacao.vue';
import ComboPreco from '@/components/Precos/ComboPreco.vue';
import { IRegiao } from '@/models/Entidades/IRegiao';
import { IEstado } from '@/models/Entidades/IEstado';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { EConceitoPrecificacao } from '@/models/Enumeradores/EConceitoPrecificacao';
import storeApp from '@/store/storeApp';

export default defineComponent({
  name: 'Configuracoes',
  components: {
    ComunicacaoApi,
    ComboTipoClientes,
    ComboFormaAtivacaoClientes,
    ComboFormaApresentacaoPrecos,
    ComboConceitoPrecificacao,
    ComboPreco,
    CampoNumerico,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const servicoUtilitario = new ServicoUtilitario();

    const state = reactive({
      configuracao: {} as IConfiguracao,
      regioesCadastradas: [] as IRegiao[],
      estadosCadastrados: [] as IEstado[],
    });

    function limparDados() {
      state.configuracao = {} as IConfiguracao;
      state.configuracao.codigo = 0;
      state.configuracao.smtp = {} as IConfiguracaoSmtp;
      state.configuracao.notificacoesEmails = [];
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.configuracao = await servicoApp.obterConfiguracoes();
      state.regioesCadastradas = await servicoUtilitario.obterTodasRegioes();
      state.estadosCadastrados = await servicoUtilitario.obterTodosEstados();
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas Configurações.');
      appBase.resposta = await servicoApp.atualizarConfiguracoes(state.configuracao);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        storeApp.mutations.atualizarConfiguracaoPlataforma(state.configuracao);
      }
      apresentarResposta();
    }

    function montaApresencaoRegiao(codigo: number): string {
      const regiao = state.regioesCadastradas.find((c) => c.codigo === codigo);
      if (regiao !== undefined) {
        return `${regiao.nome}`;
      }

      return '';
    }

    function montaApresencaoEstado(codigo: number): string {
      const estado = state.estadosCadastrados.find((c) => c.codigo === codigo);
      if (estado !== undefined) {
        return `${estado.uf} - ${estado.nome}`;
      }

      return '';
    }
    return {
      appBase,
      state,
      salvar,
      EConceitoPrecificacao,
      montaApresencaoRegiao,
      montaApresencaoEstado,
    };
  },
});
